import { gzLogUnsafe, gzLogProps } from "./gzAnalytics";

interface QueueItem extends gzLogProps {
    occuredAt: string;
    occuredAtTimestamp: number;
}

export class LocalLogQueue {
    private readonly storageKey = 'gz_log_queue';
    private queue: QueueItem[] = [];
    private isOnline: boolean = navigator.onLine;

    constructor() {
        this.loadFromStorage();
        this.setupListeners();
    }

    private setupListeners() {
        window.addEventListener('online', () => {
            this.isOnline = true;
            this.syncQueue();
        });

        window.addEventListener('offline', () => {
            this.isOnline = false;
        });

        // Sync before page unload
        window.addEventListener('beforeunload', () => {
            this.saveToStorage();
        });
    }

    private loadFromStorage() {
        const stored = localStorage.getItem(this.storageKey);
        if (stored) {
            try {
                this.queue = JSON.parse(stored);
            } catch (e) {
                console.error('Failed to load log queue:', e);
                this.queue = [];
            }
        }
    }

    private saveToStorage() {
        localStorage.setItem(this.storageKey, JSON.stringify(this.queue));
    }

    async addLog(log: gzLogProps) {
        const queueItem: QueueItem = {
            ...log,
            occuredAt: new Date().toISOString(),
            occuredAtTimestamp: Date.now(),
        };

        if (this.isOnline) {
            try {
                await this.sendLog(queueItem);
            } catch (e) {
                this.queue.push(queueItem);
                this.saveToStorage();
            }
        } else {
            this.queue.push(queueItem);
            this.saveToStorage();
        }
    }

    private async sendLog(item: QueueItem) {
        await gzLogUnsafe(item)
        this.queue = this.queue.filter(q => q.occuredAtTimestamp !== item.occuredAtTimestamp)
    }

    async syncQueue() {
        if (!this.isOnline) return;

        const failedItems: QueueItem[] = [];

        for (const item of this.queue) {
            try {
                await this.sendLog(item);
            } catch (e) {
                failedItems.push(item);
            }
        }

        this.queue = failedItems;
        this.saveToStorage();
    }
}

export const localLogQueue = new LocalLogQueue();