import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect } from "react";
import DefaultSlideShowScreen from "./components/DefaultSlideShowScreen";
import ErrorBoundary from "./components/ErrorBoundary";
import ExitPopupDialog from "./components/ExitPopupDialog";
import LoadingContainer from "./components/LoadingConitainer";
import OrganizationScreen from "./components/OrganizationScreen";
import PlaygroundBadge from "./components/PlaygroundBadge";
import TizenTestEnvironment from "./components/TizenTestEnvironment";
import LogsContainer from "./components/ui/LogsContainer";
import { AuthProvider, useAuth } from "./hooks/useAuth";
import { FullScreenProvider } from "./hooks/useFullScreen/full-screen-provider";
import { LogsProvider } from "./hooks/useLogs";
import { useTizenTestMode } from "./hooks/useTizenTestMode";
import { gzLog } from "./utils/gzAnalytics";
import isInsideIframe from "./utils/isInsideIframe";

const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    if (isInsideIframe())
      window.parent.postMessage("app_loaded", "*");
  }, [])
  useEffect(() => {
    const abortController = new AbortController();
    window.addEventListener("keyup", (e) => {
      gzLog({
        name: "KeyUp",
        eventtype: "KeyUp",
        payload: e,
      })
    }, abortController)

    return () => {
      abortController.abort();
    }
  }, [])
  useEffect(() => {
    const { outerWidth, outerHeight, innerWidth, innerHeight, screen: { width, height, availWidth, availHeight, orientation: { type, angle } } } = window
    gzLog({
      // @ts-ignore
      name: "DeviceSizeInfo", eventtype: "ScreenSizeEvent",
      payload: {
        outerWidth, outerHeight, innerWidth, innerHeight,
        screen: JSON.stringify({ width, height, availWidth, availHeight, orientation: { type, angle } }),
      },
    })
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <FullScreenProvider>
        <Main />
      </FullScreenProvider>
    </QueryClientProvider>
  );
}

function Main() {
  const { isTestMode, exitTestMode } = useTizenTestMode();

  return (
    <LogsProvider
      targetSequence={[
        "ArrowLeft",
        "ArrowRight",
        "ArrowRight",
        "ArrowLeft",
        "ArrowRight",
      ]}
    >
      <div
        aria-hidden="true"
        className="relative"
        tabIndex={-1}
        id="rootElement"
      >
        {isTestMode && <PlaygroundBadge />}
        <AuthProvider>
          <ErrorBoundary>
            <div aria-hidden="true" className="absolute">
              {isTestMode && !isInsideIframe() ? (
                <TizenTestEnvironment onClose={exitTestMode} />
              ) : (
                <Home />
              )}
            </div>
          </ErrorBoundary>
        </AuthProvider>
      </div>
    </LogsProvider>
  );
}

function Home() {
  const { screenInfoFetchStatus, screenInfo, isPreviewMode } = useAuth();

  const hasPaired = screenInfo !== null && !isPreviewMode

  return (
    <div aria-hidden="true" className="h-screen w-screen">
      {screenInfoFetchStatus !== "success" ? (
        <LoadingContainer />
      ) : (
        <ExitPopupDialog hasError={false} mountIfError={false} isPaired={hasPaired}>
          {hasPaired ? (
            <OrganizationScreen initialScreenInfo={screenInfo} key={screenInfo.id} />
          ) : (
            <DefaultSlideShowScreen />
          )}
        </ExitPopupDialog>
      )}

      <LogsContainer />
    </div>
  );
}

export default App;
