import { createContext, useContext, useEffect, useState } from "react";
import DataFetchStatus from "../../../types/DataFetchStatus";
import {
  initializeAnalyticsWithDeviceId
} from "../../utils/gzAnalytics";
import { useLogs } from "../useLogs";
import { CustomScreen } from "../useScreen/types";
import reloadPage from "../../utils/AndroidBridge";
import { isTizen } from "../../utils/isTizen";

const useAuthContext = createContext<AuthReturnProps | null>(null);
const { Provider } = useAuthContext;
type Props = {
  children: React.ReactNode;
};
export function AuthProvider({ children }: Props) {
  const [orgId, setOrgId] = useState<string | null>(localStorage.getItem("orgId"));
  const [deviceId, setDeviceId] = useState<string | null>(localStorage.getItem("deviceId"));

  const [screenInfoFetchStatus, setScreenInfoFetchStatus] =
    useState<DataFetchStatus>("idle");

  const [screenInfo, setScreenInfo] = useState<CustomScreen | null>(null);

  const serverUrl = import.meta.env.VITE_SERVER_URL ?? "";

  const { log } = useLogs();

  function storeOrgId(id?: string) {
    if (id) {
      localStorage.setItem("orgId", id);
    } else {
      localStorage.removeItem("orgId");
    }
    setOrgId(id || null)
  }

  function storeDeviceId(id?: string) {
    if (!id) {
      localStorage.removeItem("deviceId");
    } else {
      localStorage.setItem("deviceId", id);
    }
    setDeviceId(id || null)
  }

  async function refreshAccessToken(): Promise<string | null> {
    const _options: RequestInit = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      log({
        name: "TokenRefreshed",
        eventtype: "APICall",
        payload: {
          deviceId: deviceId,
          orgId: orgId,
        },
      });
      const data = await fetch(`${serverUrl}/refreshToken`, _options).then(
        (res) => res.json(),
      );

      log({
        name: "TokenRefreshed",
        eventtype: "APISuccess",
        payload: {
          deviceId: deviceId,
          orgId: orgId,
        },
      });
      return data.accessToken;
    } catch (e) {
      log({
        name: "TokenRefreshed",
        eventtype: "APIFailure",
        payload: {
          deviceId: deviceId,
          orgId: orgId,
          error: e,
        },
      });
      return null;
    }
  }

  async function getScreenInfo(softReload = false) {
    if (screenInfoFetchStatus === "fetching") return;

    try {
      if (!softReload)
        setScreenInfoFetchStatus("fetching");
      const _options: RequestInit = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          deviceId: localStorage.getItem("deviceId"),
          orgId: localStorage.getItem("orgId"),
          isPreview: isPreviewMode,
        }),
      };

      log({
        name: "ScreenAndLayoutInfo",
        eventtype: "APICall",
        payload: {
          orgId: orgId,
        },
      });
      fetch(
        import.meta.env.VITE_SERVER_URL! + "/screenAndLayoutInfo",
        _options,
      ).then(async (res) => {
        if (res.status === 200) {
          const data = await res.json();
          const orgId = data.orgId;
          log({
            name: "ScreenAndLayoutInfo",
            eventtype: "APISuccess",
            payload: {
              orgId: orgId,
              response: JSON.stringify(data),
            },
          });
          setDeviceId(data.deviceId);
          setOrgId(orgId);
          setScreenInfo({
            ...data.screen,
            integrations: data.integrations,
          });
          setScreenInfoFetchStatus("success");
        } else if (res.status === 409) {
          if (deviceId !== null) {
            storeOrgId(undefined);
            throw new Error("No screen found");
          } else {
            setScreenInfoFetchStatus("success");
          }
          log({
            name: "ScreenAndLayoutInfo",
            eventtype: "APIFailure",
            payload: {
              orgId: orgId,
              response: "409 error: " + JSON.stringify(await res.json()),
            },
          });
        } else if (res.status === 401) {
          if (orgId) {
            log({
              name: "ScreenAndLayoutInfo",
              eventtype: "APIFailure",
              payload: {
                orgId: orgId,
                response: "refreshing token",
              },
            });

            const token = await refreshAccessToken();

            log({
              name: "ScreenAndLayoutInfo",
              eventtype: "APIFailure",
              payload: {
                orgId: orgId,
                response: "refreshed token " + (token ?? ""),
              },
            });

            if (token) {
              getScreenInfo();
              return;
            } else {
              storeOrgId(undefined);
              throw new Error("No screen found");
            }
          }
          setScreenInfoFetchStatus("success");
          log({
            name: "ScreenAndLayoutInfo",
            eventtype: "APIFailure",
            payload: {
              orgId: orgId,
              response: "401 error: " + JSON.stringify(await res.text()),
            },
          });
        } else {
          setScreenInfoFetchStatus("error");
          log({
            name: "ScreenAndLayoutInfo",
            eventtype: "APIFailure",
            payload: {
              orgId: orgId,
              response: "unknown status error: " + JSON.stringify(await res.text()),
            },
          });
        }
      });
    } catch (e) {
      setScreenInfoFetchStatus("error");
      log({
        name: "ScreenAndLayoutInfo",
        eventtype: "APIFailure",
        payload: {
          orgId: orgId,
          response: JSON.stringify(e),
        },
      });
    }
  }

  useEffect(() => {
    function getOrgId() {
      const _id = localStorage.getItem("orgId");
      const _deviceId = localStorage.getItem("deviceId");
      setDeviceId(_deviceId);
      setOrgId(_id);

      initializeAnalyticsWithDeviceId(_deviceId);

      getScreenInfo();
    }

    getOrgId();
  }, []);

  const loginViaDeviceId = async (uid: string, organizationId: string) => {
    const _options: RequestInit = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        signUpToken: import.meta.env.VITE_SIGN_UP_TOKEN ?? "",
        uid: uid,
      }),
    };

    log({
      name: "LoggedIn",
      eventtype: "APICall",
      payload: {
        orgId: organizationId,
        uid: uid,
      },
    });

    await fetch(`${serverUrl}/login`, _options)
      .then((res) => res.json())
      .then((res) => {
        log({
          name: "LoggedIn",
          eventtype: "APISuccess",
          payload: {
            deviceId: res.uid,
            orgId: organizationId,
          },
        });
        storeDeviceId(res.uid);
        storeOrgId(organizationId);
        getScreenInfo()
      })
      .catch((e) => {
        log({
          name: "LoggedIn",
          eventtype: "APIFailure",
          payload: {
            deviceId: deviceId,
            orgId: organizationId,
            error: e,
          },
        });
      });
  };

  const hasDefaultOrgAndDeviceId = !!import.meta.env.VITE_DEFAULT_ORG_ID && !!import.meta.env.VITE_DEFAULT_DEVICE_ID;
  const _isPreviewMode = localStorage.getItem("isPreviewMode") || "true";
  const [isPreviewMode, setIsPreviewMode] = useState(hasDefaultOrgAndDeviceId && isTizen() && _isPreviewMode === "true");

  const storeIsPreviewMode = (isPreviewMode: boolean) => {
    localStorage.setItem("isPreviewMode", isPreviewMode.toString());
    setIsPreviewMode(isPreviewMode);
    reloadPage()
  };

  async function updateScreenInfo(screenData: Partial<CustomScreen>) {
    try {
      log({
        // @ts-ignore
        name: "UpdateScreenInfo",
        eventtype: "APICall",
        payload: {
          deviceId: deviceId,
          orgId: orgId,
          screenData,
        },
      });

      const _options: RequestInit = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          deviceId,
          orgId,
          screen: screenData,
        }),
      };

      const response = await fetch(
        `${serverUrl}/updateScreen`,
        _options
      );

      if (response.ok) {
        const data = await response.json();
        log({
          // @ts-ignore
          name: "UpdateScreenInfo",
          eventtype: "APISuccess",
          payload: {
            deviceId: deviceId,
            orgId: orgId,
            response: data,
          },
        });

        setScreenInfo(prevScreen => ({
          ...prevScreen!,
          ...screenData
        }));

        return true;
      }

      throw new Error("Failed to update screen");
    } catch (e) {
      log({
        // @ts-ignore
        name: "UpdateScreenInfo",
        eventtype: "APIFailure",
        payload: {
          deviceId: deviceId,
          orgId: orgId,
          error: e,
        },
      });
      return false;
    }
  }

  return (
    <Provider
      value={{
        orgId,
        deviceId,
        storeOrgId,
        storeDeviceId,
        refreshAccessToken,
        loginViaDeviceId,
        screenInfo,
        updateScreenInfo,
        screenInfoFetchStatus,
        getScreenInfo,
        isPreviewMode,
        storeIsPreviewMode,
      }}
    >
      {children}
    </Provider>
  );
}

// Update the interface to include updateScreenInfo
interface AuthReturnProps {
  orgId: string | null;
  deviceId: string | null;
  storeOrgId: (id?: string) => void;
  storeDeviceId: (id?: string) => void;
  refreshAccessToken: () => Promise<string | null>;
  loginViaDeviceId: (uid: string, organizationId: string) => void;
  screenInfoFetchStatus: DataFetchStatus;
  screenInfo: CustomScreen | null;
  getScreenInfo: (softReload: boolean) => void;
  isPreviewMode: boolean;
  storeIsPreviewMode: (isPreviewMode: boolean) => void;
  updateScreenInfo: (screenData: Partial<CustomScreen>) => Promise<boolean>;
}
export const useAuth: () => AuthReturnProps = () => {
  const context = useContext(useAuthContext);
  if (!context) throw "useAuth must be used within a useAuthProvider";
  return context;
};
