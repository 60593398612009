import { useEffect } from "react";
import isInsideIframe from "../utils/isInsideIframe";

let hasInteracted = isInsideIframe();
let isFirstInteraction = true;

export function useInteraction() {
    useEffect(() => {
        const handler = () => {
            if (isFirstInteraction) {
                isFirstInteraction = false;
                hasInteracted = true;
            }
        };

        window.addEventListener("click", handler);
        window.addEventListener("scroll", handler);

        return () => {
            window.removeEventListener("click", handler);
            window.removeEventListener("scroll", handler);
        };
    }, []);

    function setHasInteracted(val: boolean) {
        hasInteracted = val;
    }

    function setIsFirstInteraction(val: boolean) {
        isFirstInteraction = val;
    }

    return { hasInteracted, isFirstInteraction, setHasInteracted, setIsFirstInteraction };
}