import { createContext, useContext, useRef } from "react";
import ReactPlayer from "react-player";

interface VideoPlayerRefContextType {
  playerRef: React.RefObject<ReactPlayer>;
  onVideoEndedRef: React.MutableRefObject<() => void>;
}

const VideoPlayerRefContext = createContext<VideoPlayerRefContextType | null>(null);

export function VideoPlayerRefProvider({ children }: { children: React.ReactNode }) {
  const playerRef = useRef<ReactPlayer>(null);
  const onVideoEndedRef = useRef(() => { });

  return (
    <VideoPlayerRefContext.Provider value={{ playerRef, onVideoEndedRef }}>
      {children}
    </VideoPlayerRefContext.Provider>
  );
}

export function useVideoPlayerRef() {
  const context = useContext(VideoPlayerRefContext);
  if (!context) {
    throw new Error("useVideoPlayerRef must be used within a VideoPlayerRefProvider");
  }
  return context;
}