import { createContext, useContext, useEffect, useState } from "react";
import { v4 as randomUUID } from "uuid";
import { MusicInfo } from "../../../types/music";
import reloadPage from "../../utils/AndroidBridge";
import CustomWebSocket from "../../utils/customWebSocket";
import { isInsideDashboard } from "../../utils/isInsideDashboard";
import { useAuth } from "../useAuth";
import { useLogs } from "../useLogs";

export function useWebSocket(url: string, { refetchOnVisibile = true, refetchOnOnline = true } = {}) {
  const [socket, setSocket] = useState<CustomWebSocket>()

  function createSocket() {
    const socket = new CustomWebSocket(url)
    setSocket(socket)
  }

  useEffect(() => {
    createSocket()
  }, [])

  useEffect(() => {
    return () => {
      socket?.close()
    }
  }, [socket])

  useEffect(() => {
    if (!refetchOnVisibile) return;

    const abortController = new AbortController()

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        createSocket()
      }
    }, abortController)

    return () => {
      abortController.abort()
    }
  }, [refetchOnVisibile])

  useEffect(() => {
    if (!refetchOnOnline) return;

    const abortController = new AbortController()

    window.addEventListener("online", () => {
      createSocket()
    }, abortController)

    return () => {
      abortController.abort()
    }
  }, [refetchOnOnline])

  return [socket, createSocket] as const
}

const screenInfoContext = createContext<ScreenSocketInfoReturnProps | null>(
  null,
);
const { Provider } = screenInfoContext;
type Props = {
  children: React.ReactNode;
  onScreenChanged: (screenInfo: any) => void;
  onLayoutChanged: (layoutInfo: any) => void;
  onPlaylistChanged: (playlistInfo: any) => void;
  onMusicChanged: (musicInfo: MusicInfo[] | undefined, action?: 'pause' | 'resume' | 'disable' | 'enable') => void;
};
export function ScreenSocketInfoProvider({
  children,
  onScreenChanged,
  onMusicChanged,
  onLayoutChanged,
  onPlaylistChanged,
}: Props) {
  const { deviceId, orgId, screenInfo: _screenInfo } = useAuth();
  // const musicInfos = screenInfo?.music_infos ?? [];

  const [isOnline, setIsOnline] = useState(false);

  const { log } = useLogs();

  const [socket, reconnectSocket] = useWebSocket(
    import.meta.env.VITE_WS_URL! +
    "/" +
    import.meta.env.VITE_WS_AUTHENTICATED_SCREEN_NAMESPACE! +
    "/public/websocket",
  )

  // useEffect(() => {
  //   onMusicChanged(musicInfos);
  // }, [musicInfos])

  useEffect(() => {
    function setupSocket() {
      if (!socket) return
      setIsOnline(false);

      const _previousUUid = localStorage.getItem("previous_uuid");

      const uuid = _previousUUid ?? randomUUID();
      localStorage.setItem("uuid", uuid);

      socket.onOpen = () => {
        setIsOnline(false);

        if (deviceId)
          socket.emit(
            "joinRoom",
            {
              roomId: deviceId,
              id: deviceId,
              uniqueId: uuid,
              orgId: orgId,
              isFromReload: _previousUUid ? true : false,
              from: isInsideDashboard() ? "dashboard" : "player",
            },
            () => {
              setIsOnline(true);
              localStorage.removeItem("previous_uuid");
            },
          );

        log({
          name: "SocketConnected",
          eventtype: "SocketEvent",
          payload: {
            deviceId,
          },
        });
      };

      socket.onClose = () => {
        setIsOnline(false);
        log({
          name: "PairSocketDisconnected",
          eventtype: "SocketEvent",
          payload: {
            deviceId,
          },
        });
        reconnectSocket()
      };

      socket.onError = () => {
        setIsOnline(false);
      };

      socket.onMessage = (message) => {
        try {
          const data = JSON.parse(message.data);
          const event = data.event;
          log({
            name: "SocketEvent",
            eventtype: "SocketEvent",
            payload: {
              data: data,
            },
          });

          switch (event) {
            case "broadcastMessage": {
              const _data = data.data;
              if (_data.from === "dashboard") {
                switch (_data.content) {
                  case "restart":
                    reloadPage();
                    break;
                  case "musicPaused":
                    log({
                      name: "MusicChanged",
                      eventtype: "SocketListenEvent",
                      payload: {
                        data: _data,
                      },
                    });
                    onMusicChanged(_data.data, 'pause');
                    break;
                  case "musicChanged":
                    log({
                      name: "MusicChanged",
                      eventtype: "SocketListenEvent",
                      payload: {
                        data: _data,
                      },
                    });
                    onMusicChanged(_data.data);
                    break;
                  case "musicResumed":
                    log({
                      name: "MusicChanged",
                      eventtype: "SocketListenEvent",
                      payload: {
                        data: _data,
                      },
                    });
                    onMusicChanged(_data.data, 'resume');
                    break;
                  case "musicDisabled":
                    log({
                      name: "MusicChanged",
                      eventtype: "SocketListenEvent",
                      payload: {
                        data: _data,
                      },
                    });
                    onMusicChanged(_data.data, 'disable');
                    break;
                  case "musicEnabled":
                    log({
                      name: "MusicChanged",
                      eventtype: "SocketListenEvent",
                      payload: {
                        data: _data,
                      },
                    });
                    onMusicChanged(_data.data, 'enable');
                    break;
                  case "screenChanged":
                    log({
                      name: "ScreenChanged",
                      eventtype: "SocketListenEvent",
                      payload: {
                        data: _data.data,
                      },
                    });
                    onScreenChanged(_data.data);
                    break;
                  case "layoutChanged":
                    log({
                      name: "LayoutChanged",
                      eventtype: "SocketListenEvent",
                      payload: {
                        data: data.data,
                      },
                    });
                    onLayoutChanged(_data.data);
                    break;
                  case "playlistChanged":
                    log({
                      name: "PlaylistChanged",
                      eventtype: "SocketListenEvent",
                      payload: {
                        data: data.data,
                      },
                    });
                    onPlaylistChanged(_data.data);
                    break;
                }
              }
              break;
            }

            case "heartbeat":
              socket.emit("heartBeatBack", {});
              break;

            default:
              break;
          }
        } catch (e) {
          log({
            name: "SocketError",
            eventtype: "Error",
            payload: {
              error: e,
            },
          });
        }
      };
    }
    setupSocket()
  }, [socket, deviceId]);

  return (
    <Provider
      value={{
        socket: socket || null,
        isOnline,
      }}
    >
      {children}
    </Provider>
  );
}

interface ScreenSocketInfoReturnProps {
  socket: CustomWebSocket | null;
  isOnline: boolean;
}

export const useScreenSocketInfo: () => ScreenSocketInfoReturnProps = () => {
  const context = useContext(screenInfoContext);
  if (!context)
    throw "useScreenSocket must be used within a ScreenSocketProvider";
  return context;
};
