import { useEffect, useRef, useState } from 'react';

export default function SamsungRemote() {
    const remoteRef = useRef<HTMLDivElement>(null);
    const [position, setPosition] = useState({ x: window.innerWidth - 250, y: 20 });
    const dragStartPos = useRef({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);

    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        dragStartPos.current = {
            x: e.clientX - position.x,
            y: e.clientY - position.y
        };
    };

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            if (!isDragging) return;

            setPosition({
                x: e.clientX - dragStartPos.current.x,
                y: e.clientY - dragStartPos.current.y
            });
        };

        const handleMouseUp = () => {
            setIsDragging(false);
        };

        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    const emitKeyEvent = (key: string, e: React.MouseEvent) => {
        e.stopPropagation();

        window.dispatchEvent(new KeyboardEvent('keyup', {
            key,
            code: key,
            bubbles: true,

            cancelable: true,
            composed: true,
        }));
    };

    return (
        <div
            ref={remoteRef}
            style={{
                position: 'fixed',
                left: `${position.x}px`,
                top: `${position.y}px`,
                zIndex: 9999,
                cursor: isDragging ? 'grabbing' : 'grab',
                userSelect: 'none',
            }}
            onMouseDown={handleMouseDown}
        >
            <div className="flex w-[200px] flex-col gap-5 rounded-[20px] bg-white p-5 shadow-lg">
                <button
                    className="remote-btn self-end text-red-500"
                    onClick={(e) => emitKeyEvent('Power', e)}
                >
                    ⏻
                </button>

                {/* Update all button onClick handlers to pass the event */}
                <div className="flex justify-between gap-2.5">
                    <button className="remote-btn" onClick={(e) => emitKeyEvent('123', e)}>123</button>
                    <button className="remote-btn" onClick={(e) => emitKeyEvent('Microphone', e)}>🎤</button>
                    <button className="remote-btn" onClick={(e) => emitKeyEvent('PIP', e)}>⎘</button>
                </div>

                <div className="relative grid h-[120px] place-items-center gap-1">
                    <button className="remote-btn absolute top-0" onClick={(e) => emitKeyEvent('ArrowUp', e)}>↑</button>
                    <button className="remote-btn absolute left-0" onClick={(e) => emitKeyEvent('ArrowLeft', e)}>←</button>
                    <button className="remote-btn" onClick={(e) => emitKeyEvent('Enter', e)}>OK</button>
                    <button className="remote-btn absolute right-0" onClick={(e) => emitKeyEvent('ArrowRight', e)}>→</button>
                    <button className="remote-btn absolute bottom-0" onClick={(e) => emitKeyEvent('ArrowDown', e)}>↓</button>
                </div>

                <div className="flex justify-between gap-2.5">
                    <button className="remote-btn" onClick={(e) => emitKeyEvent('Escape', e)}>↶</button>
                    <button className="remote-btn" onClick={(e) => emitKeyEvent('Home', e)}>⌂</button>
                    <button className="remote-btn" onClick={(e) => emitKeyEvent('Play', e)}>▶</button>
                </div>

                <div className="flex justify-center gap-5">
                    <button className="remote-btn" onClick={(e) => emitKeyEvent('VolumeUp', e)}>+</button>
                    <button className="remote-btn" onClick={(e) => emitKeyEvent('VolumeDown', e)}>−</button>
                </div>

                <div className="grid grid-cols-2 gap-2.5">
                    <button className="shortcut-btn" onClick={(e) => emitKeyEvent('Netflix', e)}>NETFLIX</button>
                    <button className="shortcut-btn" onClick={(e) => emitKeyEvent('Prime', e)}>prime</button>
                    <button className="shortcut-btn" onClick={(e) => emitKeyEvent('Disney', e)}>Disney+</button>
                    <button className="shortcut-btn" onClick={(e) => emitKeyEvent('TVPlus', e)}>TV Plus</button>
                </div>
            </div>
        </div>
    );
}