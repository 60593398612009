import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";
import "./index.css";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://4d79fb897e80464191ad698d37f35c3a@o123862.ingest.us.sentry.io/4504444532621312",
  //dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
});

document.addEventListener('keyup', (e) => {
  if (!e.bubbles) document.dispatchEvent(new KeyboardEvent("keyup", { ...e, bubbles: true }))
}, true);

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
