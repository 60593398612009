import { createContext, useContext, useRef, useState } from "react";
import { useInteraction } from "./useInteraction";
import { useAuth } from "./useAuth";
import { useMutation } from "@tanstack/react-query";

interface AudioContextType {
    onPlay: () => void;
    onPause: () => void;
    onVolumeChange: (volume: number | undefined) => void;
    onTrackChange: () => void;
    isPlaying: boolean;
    isPending: boolean;
    volume: number | undefined;
}

const AudioContext = createContext<AudioContextType | null>(null);

export type AudioProviderProps = React.PropsWithChildren<{
    url: string,
    onError: () => Promise<void>
}>

export function AudioProvider({ children, url, onError }: AudioProviderProps) {
    const { screenInfo } = useAuth()
    const { hasInteracted } = useInteraction()
    const [isPlaying, setIsPlaying] = useState(hasInteracted && !!screenInfo?.music_playing)
    const [volume, setVolume] = useState<number>()
    const audioRef = useRef<HTMLAudioElement | null>()

    const { mutateAsync: createAudio } = useMutation({
        async mutationFn() {
            const audio = new Audio(url)
            audio.crossOrigin = "anonymous"
            audio.load()
            return new Promise<typeof audio>((resolve) => {
                audio.oncanplaythrough = () => resolve(audio)
                audio.onerror = async () => {
                    await onError()
                    disposeAudio()
                    resolve(await createAudio())
                }
            })
        },
    })

    function disposeAudio() {
        if (!audioRef.current) return
        audioRef.current.pause()
        audioRef.current.oncanplaythrough = null
        audioRef.current.onerror = null
        audioRef.current.src = ''
        audioRef.current.load()
        audioRef.current.remove()
        audioRef.current = null
    }

    const { mutateAsync: onTrackChange, isPending: isTrackChanging } = useMutation({
        async mutationFn() {
            disposeAudio()
            onPlay()
        },
    })

    const { mutateAsync: onPlay, isPending: isPlayLoading } = useMutation({
        async mutationFn() {
            if (isPlayLoading) return;
            audioRef.current ??= await createAudio()
            await audioRef.current?.play()
            setIsPlaying(true)
        },
    })
    function onPause() {
        audioRef.current?.pause()
        setIsPlaying(false)
    };
    function onVolumeChange(volume: number | undefined) {
        if (!audioRef.current) return
        if (!volume) return setVolume(volume)

        if (volume < 0 || volume > 1) throw new Error("Volume must be between 0 and 1")

        audioRef.current.volume = volume
        setVolume(volume)
    };

    const isPending = isPlayLoading || isTrackChanging

    return (
        <AudioContext.Provider value={{ onPlay, onPause, onVolumeChange, onTrackChange, isPlaying, isPending, volume }}>
            {children}
        </AudioContext.Provider>
    );
}

export function useAudio() {
    const context = useContext(AudioContext);
    if (!context) {
        throw new Error("useAudio must be used within an AudioProvider");
    }
    return context;
}