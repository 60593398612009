import React from "react";
import { gzLog } from "../utils/gzAnalytics";
import ExitPopupDialog from "./ExitPopupDialog";

interface Props {
  children?: React.ReactNode;
}

interface State {
  hasError: boolean;
}
class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  constructor(props: Props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(_error: any) {
    return { hasError: true };
  }
  componentDidCatch(error: any, errorInfo: any) {
    try {
      gzLog({
        name: "ErrorBoundary",
        eventtype: "Error",
        payload: {
          error:
            typeof error === "string"
              ? error
              : typeof error === "object"
                ? JSON.stringify(error)
                : "Unknown error",
        },
      });
    } catch (_e) {
      console.log({ error, errorInfo });
    }
  }
  render() {
    return (
      <ExitPopupDialog hasError={this.state.hasError} mountIfError>
        {this.props.children}
      </ExitPopupDialog>
    );
  }
}

export default ErrorBoundary;
