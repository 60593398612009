import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { AudioProvider, useAudio } from "../hooks/useAudio";
import { useAuth } from "../hooks/useAuth";
import { useFullScreen } from "../hooks/useFullScreen";
import { useLogs } from "../hooks/useLogs";
import { ScreenInfoProvider, useScreenInfo } from "../hooks/useScreen";
import { CustomScreen } from "../hooks/useScreen/types";
import {
  ScreenSocketInfoProvider,
  useScreenSocketInfo,
} from "../hooks/useScreenSocket";
import { VideoPlayerRefProvider } from "../hooks/useVideoPlayerRef";
import { cn } from "../lib/utils";
import { isInsideDashboard } from "../utils/isInsideDashboard";
import isInsideIframe from "../utils/isInsideIframe";
import AssetPlayer from "./Players/AssetPlayer";
import LayoutPlayer from "./Players/LayoutPlayer";
import PlaylistPlayer from "./Players/PlaylistPlayer";
import VideoPlayer from "./Players/VideoPlayer";
import AnimatingGradientBackground from "./ui/AnimatingGradientBackground";
import { MusicController } from "./ui/MusicController";

type Props = {
  initialScreenInfo: CustomScreen | null;
};
const OrganizationScreen = (props: Props) => {
  return (
    <ScreenInfoProvider {...props}>
      <VideoPlayerRefProvider>
        <OrganizationScreenPresenter />
      </VideoPlayerRefProvider>
    </ScreenInfoProvider>
  );
};

function OrganizationScreenPresenter() {
  const handle = useFullScreen();
  const { screenInfo } = useAuth()

  const [showFullScreenButton, setShowFullScreenButton] = useState(false);
  const timerRef = useRef<number | null>(null);

  // Helper function to hide the button after 3 seconds
  const hideButton = useCallback(() => {
    setShowFullScreenButton(false);
  }, []);

  // Track when mouse enters and leaves
  const handleMouseEnter = () => {
    setShowFullScreenButton(true);
    // Clear any existing timeout to prevent it from hiding the button prematurely
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const handleMouseLeave = () => {
    // Set a new timeout to hide the button after 3 seconds
    timerRef.current = window.setTimeout(hideButton, 3000);
  };

  useEffect(() => {
    return () => {
      // Clear any ongoing timeout when the component unmounts
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const { mutateAsync: removeMusicListener } = useMusicListener.removeListener()

  return (
    <div
      className="h-screen w-screen bg-white"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {handle.shouldShowFullScreenButton && (
        <>
          <button
            onClick={handle.enter}
            className={`text absolute right-8 top-8 z-10 rounded-md bg-slate-800 p-2 text-white transition-all ${!showFullScreenButton ? "opacity-0" : ""
              }`}
          >
            <FullScreenButton />
          </button>
        </>
      )}
      <div
        aria-hidden="true"
        className="flex h-screen w-screen flex-col items-center justify-center gap-4 bg-white text-black"
      >
        {screenInfo && (
          <AudioProvider url={screenInfo.music_infos?.[0]?.url ?? ""} onError={async () => {
            await removeMusicListener({ zone_id: screenInfo!.zone_id! })
            await new Promise((resolve) => {
              setTimeout(resolve, 5000)
            })
          }}>
            <Player
              key={screenInfo.id}
              showFullScreenButton={showFullScreenButton}
            />
          </AudioProvider>
        )}
      </div>
    </div>
  );
}

function Player({ showFullScreenButton = true }) {
  const { screenInfo } = useAuth()
  const { onLayoutChanged, onScreenChanged, onPlaylistChanged, onMusicChanged } =
    useScreenInfo();
  const { onTrackChange } = useAudio()

  return <ScreenSocketInfoProvider
    onMusicChanged={(musicInfo, action) => {
      if (!action)
        onTrackChange()
      onMusicChanged(musicInfo)
    }}
    onLayoutChanged={onLayoutChanged}
    onScreenChanged={onScreenChanged}
    onPlaylistChanged={onPlaylistChanged}
  >
    {
      !isInsideDashboard() &&
      <MusicPlayer showFullScreenButton={showFullScreenButton} />
    }
    <OrganizationScreenPlayer screenInfo={screenInfo} />
  </ScreenSocketInfoProvider>
}

function MusicPlayer({ showFullScreenButton }: { showFullScreenButton: boolean }) {
  const { musicInfos } = useScreenInfo()
  const { getScreenInfo, screenInfo } = useAuth()
  const { deviceId } = useAuth()
  const { socket } = useScreenSocketInfo()
  const { musicAction, setMusicAction } = useScreenInfo()

  useEffect(() => {
    if (musicAction === 'resume' && !musicInfos?.length) {
      getScreenInfo(true)
    }
  }, [musicAction, musicInfos])

  const { onPlay, onPause, onVolumeChange } = useAudio()

  if (!screenInfo?.zone_id) return null;
  const track = screenInfo.music_infos?.[0] ?? null
  if (!track) return null

  const dispatch = musicAction === undefined

  return <MusicController
    action={musicAction}
    className={cn("absolute top-8 z-10 left-1/2", {
      "opacity-0": !showFullScreenButton || navigator.userAgent.includes("TV") || isInsideIframe(),
    })}
    onPlay={() => {
      onPlay()
      if (dispatch) {
        socket?.emit("broadcastMessage", {
          roomId: deviceId ?? "",
          messageContent: "musicResumed",
          messageData: {
            from: "player",
          },
        })
      }
      setMusicAction(undefined)
    }}
    onPause={() => {
      onPause()
      if (dispatch) {
        socket?.emit('broadcastMessage', {
          roomId: deviceId ?? "",
          messageContent: "musicPaused",
          messageData: {
            from: "player",
          },
        })
      }
      setMusicAction(undefined)
    }}
    onVolumeChange={(volume) => {
      onVolumeChange(volume)
    }}
  />
}

function OrganizationScreenPlayer({
  screenInfo,
}: {
  screenInfo?: CustomScreen | null;
}) {
  const { isOnline } = useScreenSocketInfo();
  const { showLogs } = useLogs();

  return (
    <div className="h-full w-full">
      {showLogs && (
        <div className="absolute left-2 top-2 z-10 flex items-center justify-center gap-2 rounded-full border bg-white px-4 py-1 text-sm">
          <div
            className={`h-3 w-3 rounded-full ${isOnline ? "bg-green-500" : "bg-red-500"
              }`}
          ></div>
          {isOnline ? "Online" : "Offline"}
        </div>
      )}
      {screenInfo?.playlist ? (
        <PlaylistPlayer playlist={screenInfo.playlist} screen={screenInfo} />
      ) : screenInfo?.layout ? (
        <LayoutPlayer layoutInfo={screenInfo.layout} />
      ) : screenInfo?.asset ? (
        <AssetPlayer asset={screenInfo.asset} screen={screenInfo} />
      ) : screenInfo?.video ? (
        <VideoPlayer loop url={screenInfo.video.url ?? ""} />
      ) : (
        <div
          className={`relative z-0 flex h-full w-full flex-col items-center justify-center gap-8 overflow-hidden text-[#3d2b24] font-exo`}
          aria-hidden="true"
        >
          <h1 aria-hidden="true" className="text-[calc(4px+7vw)] font-semibold">
            Hi there 👋
          </h1>
          <h2 aria-hidden="true" className="text-center text-[calc(4px+3vw)]">
            Waiting for you to select a design from <br /> your account to
            display here...
          </h2>
          <AnimatingGradientBackground className="absolute -z-10 h-full w-full opacity-50" />
        </div>
      )}
    </div>
  );
}

const FullScreenButton = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 3H9V5H6.46173L11.3047 9.84298L9.8905 11.2572L5 6.3667V9H3V3Z"
        fill="currentColor"
      />
      <path
        d="M3 21H9V19H6.3764L11.3046 14.0718L9.89038 12.6576L5 17.548V15H3V21Z"
        fill="currentColor"
      />
      <path
        d="M15 21H21V15H19V17.5244L14.1332 12.6576L12.719 14.0718L17.6472 19H15V21Z"
        fill="currentColor"
      />
      <path
        d="M21 3H15V5H17.5619L12.7189 9.84301L14.1331 11.2572L19 6.39032V9H21V3Z"
        fill="currentColor"
      />
    </svg>
  );
};
export const useMusicListener = {
  $metadata: {
    headers() {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${import.meta.env.VITE_MUSIC_API_KEY}`);
      return headers
    },
    fetchers: {
      async getAll() {
        const headers = useMusicListener.$metadata.headers()
        const apiUrl = `${import.meta.env.VITE_MUSIC_API_URL}/api/partner/listeners`

        const resp = await fetch(apiUrl, {
          method: "POST",
          headers,
        })

        const musicInfo = await resp.json()
        if (musicInfo.status === "success") {
          return musicInfo.listeners
        }
        throw new Error(musicInfo.message)
      },
      async getWithZone(zone_id: number) {
        const listeners = await useMusicListener.$metadata.fetchers.getAll()
        return listeners.find((e: any) => e.zone_id === zone_id)
      }
    },
  },
  removeListener() {
    const headers = this.$metadata.headers()
    const get = this.$metadata.fetchers.getWithZone
    return useMutation({
      async mutationFn({ zone_id }: { zone_id: number }) {
        const listener = await get(zone_id)
        if (!listener) return

        const apiUrl = `${import.meta.env.VITE_MUSIC_API_URL}/api/partner/listener/remove`

        const resp = await fetch(apiUrl, {
          method: "POST",
          headers,
          body: JSON.stringify({
            id: listener.id,
          })
        })
        const json = await resp.json()
        if (json.status === "success") {
          return json
        }
        throw new Error(json.message)
      }
    })
  }
}
export default OrganizationScreen;
