export const tizenLoaderScript = `
    var init = function() {
        const iframe = document.getElementById("my-iframe");
        if(!iframe) return
        const loadingIndicator = document.getElementById("loading-indicator");
        const errorMessage = document.getElementById("error-message");

        loadingIndicator.style.display = "block";
        iframe.style.display = "block";
        iframe.style.opacity = 0;
        errorMessage.style.display = "block";

        let isDialogOpen = false;
        let iframeLoaded = false;
        let listenCount = 0;

        function showSnackbar(message) {
            const x = document.getElementById("snackbar");
            x.innerText = message;
            x.className = "show";
            setTimeout(function() {
                x.className = x.className.replace("show", "");
            }, 3000);
        }

        function showDialog(message) {
            const customDialog = document.getElementById("customDialog");
            const dialogMessage = document.getElementById("dialogMessage");
            dialogMessage.innerText = message || "N/A";
            customDialog.style.display = "flex";
            isDialogOpen = true;
            document.getElementById("dialogConfirmBtn").focus();
        }

        function closeDialog() {
            const customDialog = document.getElementById("customDialog");
            customDialog.style.display = "none";
            isDialogOpen = false;
        }

        function onError(message) {
            loadingIndicator.style.display = "none";
            iframe.style.display = "none";
            errorMessage.style.display = "block";
            document.getElementById("error-message-text").innerText = message || "Error loading app";
            const retrybutton = document.getElementById("retry-button");
            retrybutton.focus();
            retrybutton.onclick = function() {
                location.reload();
            };
            clearInterval(intervalFunc);
        }

        const intervalFunc = setInterval(function() {
            if (listenCount >= 15) {
                listenCount = 0;
                return onError("Connection timed out. Please try again.");
            }
            listenCount++;
        }, 1000);
        
        document.addEventListener("keyup", function(e) {
            switch (e.code) {
                case "ArrowLeft":
                case "ArrowRight":
                case "ArrowUp":
                case "ArrowDown":
                    if (isDialogOpen) {
                        document.getElementById("dialogConfirmBtn").focus();
                    } else if (!iframeLoaded) {
                        document.getElementById("retry-button").focus();
                    }
                    break;

                case "Escape":
                    if (isDialogOpen) {
                        closeDialog();
                    } else if (!iframeLoaded) {
                        tizen.application.getCurrentApplication().exit();
                    }
                    break;
            }
        });

        window.addEventListener("message", function(e) {
                switch (e.data) {
                    case "exit_app":
                        tizen.application.getCurrentApplication().exit();
                        break;

                    case "app_loaded":
                        iframeLoaded = true;
                        iframe.style.opacity = 1;
                        loadingIndicator.style.display = "none";
                        errorMessage.style.display = "none";
                        clearInterval(intervalFunc);
                        break;
                }
        });

        iframe.addEventListener("load", function() {
            iframeLoaded = true;
            iframe.style.display = "block";
            loadingIndicator.style.display = "none";
            errorMessage.style.display = "none";
            clearInterval(intervalFunc);
        });

        iframe.addEventListener("error", function(e) {
            onError(e.message);
        });

        loadingIndicator.style.display = "flex";
        iframe.style.display = "none";

        if (webapis) {
            var activeConnectionType = webapis.network.getActiveConnectionType();

            if (activeConnectionType === 0) {
                onError("No network detected");
            }

            webapis.network.addNetworkStateChangeListener(function(value) {
                if (value === webapis.network.NetworkState.GATEWAY_DISCONNECTED) {
                    showDialog("Network disconnected");
                } else if (value === webapis.network.NetworkState.GATEWAY_CONNECTED) {
                    showSnackbar("Back online");
                    location.reload();
                }
            });
        }
    };
    setTimeout(init, 5)
    // window.onload = init;
`;