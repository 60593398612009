import { useMutation, useQuery } from "@tanstack/react-query";
import { MusicIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { useScreenSocketInfo } from "../hooks/useScreenSocket";
import { cn } from "../lib/utils";
import { LoadingSpinner } from "./LoadingConitainer";
import { ScrollArea } from "./scroll-area";
import { ButtonCard, CardHeader, CardTitle } from "./ui/card";
import { useAudio } from "../hooks/useAudio";

interface MusicSelectionDialogProps {
  onClose: () => void;
}

export type MusicChannel = {
  id: number
  name: string
  description: string
}
type MusicApiFetchResponse<TSuccess, TFailure = { message: string }> = ({
  status: "success",
} & TSuccess) | ({
  status: "error" | "failed"
} & TFailure)
export type ChangePlaylistResponse = MusicApiFetchResponse<{}>
export type FetchPlaylistResponse = MusicApiFetchResponse<{
  playlists: MusicChannel[]
}>

export const useMusicQuery = {
  $metadata: {
    headers() {
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${import.meta.env.VITE_MUSIC_API_KEY}`);
      return headers
    },
  },
  changePlaylist() {
    const headers = this.$metadata.headers()
    return useMutation({
      async mutationFn({ zone_id, playlist_id }: { zone_id: number, playlist_id: number }) {
        const apiUrl = `${import.meta.env.VITE_MUSIC_API_URL}/api/partner/location/zone/change_playlist`

        const resp = await fetch(apiUrl, {
          method: "POST",
          headers,
          body: JSON.stringify({
            zone_id,
            playlist_id,
          })
        })

        const json = await resp.json() as ChangePlaylistResponse

        if (json.status === "success") {
          return resp
        }

        throw new Error(json.message)
      }
    })
  },
  playlists() {
    return useQuery({
      queryKey: ["music-playlists"],
      staleTime: Infinity,
      queryFn: async () => {
        const apiUrl = `${import.meta.env.VITE_MUSIC_API_URL}/api/partner/playlists`

        const resp = await fetch(apiUrl, {
          method: "POST",
          headers: this.$metadata.headers(),
        })

        const musicInfo = await resp.json() as FetchPlaylistResponse
        if (musicInfo.status === "success") {
          return musicInfo.playlists
        }
        throw new Error(musicInfo.message)
      },
    })
  },
}

export default function MusicSelectionDialog(props: MusicSelectionDialogProps) {
  return (
    <div
      id="music-popup"
      className="absolute flex h-screen w-screen items-center justify-center bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center gap-4 rounded-2xl bg-white px-12 py-12 text-black md:px-[5vw] md:py-[5vh]">
        <div className="flex flex-col gap-2 max-h-[60vh] overflow-y-auto">
          <MusicGridFetcher {...props} />
        </div>
      </div>
    </div>
  );
}

function MusicGridFetcher(props: MusicSelectionDialogProps) {
  const { isLoading, data = [] } = useMusicQuery.playlists();

  if (isLoading) return <LoadingSpinner />

  return <MusicGridPresenter musicChannels={data} {...props} />
}

function MusicGridPresenter({ musicChannels, onClose }: { musicChannels: MusicChannel[] } & MusicSelectionDialogProps) {
  const { socket } = useScreenSocketInfo()
  const { screenInfo: screen, updateScreenInfo } = useAuth()
  const { onTrackChange } = useAudio()

  const lastMusicId = screen?.last_music_id

  const playingChannel = !lastMusicId ? undefined : musicChannels.find(c => c.id === lastMusicId)

  const changePlaylistMutation = useMusicQuery.changePlaylist()
  const [focusedMusicIndex, setFocusedMusicIndex] = useState(0)

  function pauseMusic() {
    updateScreenInfo({
      id: screen!.id,
      music_playing: false,
    })
    socket?.emit("broadcastMessage", {
      roomId: screen!.id,
      messageContent: "musicPaused",
      messageData: {
        from: "player",
      },
    })
  }

  async function playMusic(zone_id: number, playlist_id: number) {
    updateScreenInfo({
      id: screen!.id,
      last_music_id: playlist_id,
      music_playing: true,
    })
    await changePlaylistMutation.mutateAsync({ zone_id, playlist_id })
    socket?.emit("broadcastMessage", {
      roomId: screen!.id,
      messageContent: "musicPlaying",
      messageData: {
        from: "player",
        playlist_id: playlist_id
      },
    })
    socket?.emit("broadcastMessage", {
      roomId: screen!.id,
      messageContent: "musicChanged",
      messageData: {
        from: "player",
        playlist_id: playlist_id
      },
    })
    onTrackChange()
    onClose()
  }

  const musicChannelIds = useMemo(
    () => musicChannels.map(m => m.id.toString()),
    [musicChannels]
  )

  function focusIndex(i: number) {
    document.getElementById(musicChannelIds[i])?.focus()
  }

  function getGridColumns() {
    const gridElement = document.querySelector('.grid');
    if (!gridElement) return 1;
    const style = window.getComputedStyle(gridElement);
    return style.gridTemplateColumns.split(' ').length;
  }

  function stopPropagationAndChangeFocusBy(e: KeyboardEvent, direction: 'left' | 'right' | 'up' | 'down') {
    e.stopPropagation();
    const columns = getGridColumns();
    const totalItems = musicChannels.length;

    setFocusedMusicIndex((currentIndex) => {
      let newIndex = currentIndex;
      const currentCol = currentIndex % columns;

      switch (direction) {
        case 'right':
          newIndex = (currentIndex + 1) % totalItems;
          break;
        case 'left':
          newIndex = (currentIndex - 1 + totalItems) % totalItems;
          break;
        case 'up': {
          const targetIndex = currentIndex - columns;
          newIndex = targetIndex < 0 ?
            currentCol + (Math.floor((totalItems - 1) / columns) * columns) :
            targetIndex;
          if (newIndex >= totalItems) {
            newIndex = targetIndex - columns;
          }
          break;
        }
        case 'down': {
          const targetIndex = currentIndex + columns;
          newIndex = targetIndex >= totalItems ?
            currentCol :
            targetIndex;
          break;
        }
      }

      focusIndex(newIndex);
      return newIndex;
    });
  }

  useEffect(() => {
    focusIndex(focusedMusicIndex);
    function listener(e: KeyboardEvent) {
      switch (e.code) {
        case "ArrowRight":
          stopPropagationAndChangeFocusBy(e, 'right');
          break;
        case "ArrowLeft":
          stopPropagationAndChangeFocusBy(e, 'left');
          break;
        case "ArrowUp":
          stopPropagationAndChangeFocusBy(e, 'up');
          break;
        case "ArrowDown":
          stopPropagationAndChangeFocusBy(e, 'down');
          break;
        case "Escape":
          e.stopPropagation();
          onClose();
      }
    }

    document.addEventListener("keyup", listener);
    return () => document.removeEventListener("keyup", listener);
  }, [focusedMusicIndex, musicChannelIds]);

  return (
    <ScrollArea id="rootMusicPickerElement" className="container h-svh">
      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {musicChannels.map((channel, i) => {
            const isPlaying = screen?.music_playing && playingChannel?.id === channel.id
            return <ButtonCard
              key={musicChannelIds[i]}
              id={musicChannelIds[i]}
              aria-hidden="true"
              tabIndex={-1}
              onClick={() => {
                if (isPlaying) {
                  pauseMusic()
                } else if (screen?.zone_id) {
                  playMusic(screen.zone_id, channel.id)
                }
              }}
              className={cn("h-full transition-all duration-300 hover:shadow-lg hover:-translate-y-1", {
                "border-accent bg-green-200": isPlaying,
                "border-ring": channel.id === screen?.last_music_id,
                "focus:border-ring focus:scale-110": true,
              })}
            >
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <MusicIcon className="h-5 w-5" />
                  <h1 className="w-full">
                    {channel.name}
                  </h1>
                </CardTitle>
              </CardHeader>
            </ButtonCard>
          })}
        </div>
      </div>
    </ScrollArea>
  )
}