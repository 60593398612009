import { useEffect, useState, useRef } from 'react';
import isInsideIframe from '../utils/isInsideIframe';

export function useTizenTestMode() {
    const keyEnterCountRef = useRef(0)
    const _isInitialTestMode = localStorage.getItem('isTestMode') === 'true';
    const [isTestMode, setIsTestMode] = useState(_isInitialTestMode);
    const timerRef = useRef<number>();
    const lastPressRef = useRef<number>(0);

    function setEnterKeyCount(val: number) {
        keyEnterCountRef.current = val
    }

    useEffect(() => {
        localStorage.setItem('isTestMode', isTestMode.toString());
    }, [isTestMode])

    useEffect(() => {
        if (isInsideIframe()) return
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                const now = Date.now();

                // Reset count if more than 5 seconds have passed since last press
                if (now - lastPressRef.current > 5000) {
                    setEnterKeyCount(1);
                } else {
                    const prev = keyEnterCountRef.current;
                    const newCount = prev + 1;
                    if (newCount >= 5) {
                        setIsTestMode(!isTestMode);
                        return setEnterKeyCount(0);
                    }
                    setEnterKeyCount(newCount);
                }

                lastPressRef.current = now;

                // Clear existing timer
                if (timerRef.current) {
                    window.clearTimeout(timerRef.current);
                }

                // Set new timer to clear count after 5 seconds
                timerRef.current = window.setTimeout(() => {
                    setEnterKeyCount(0);
                }, 5000);
            }
        };

        window.addEventListener('keyup', handleKeyPress);
        return () => {
            window.removeEventListener('keyup', handleKeyPress);
            if (timerRef.current) {
                window.clearTimeout(timerRef.current);
            }
        };
    }, [isTestMode]);

    const exitTestMode = () => {
        setIsTestMode(false);
        setEnterKeyCount(0);
    };

    return {
        isTestMode: isInsideIframe() ? false : isTestMode,
        exitTestMode
    };
}