import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import reloadPage, { isAndroid, quitAndroidApp } from "../utils/AndroidBridge";
import isInsideIframe from "../utils/isInsideIframe";
import CustomButton from "./CustomButton";
import MusicSelectionDialog from "./MusicSelectionDialog";
import { ResetAppButton } from "./ResetAppButton";

export default function ExitPopupDialog({
  children,
  hasError,
  idPrefix = "",
  mountIfError,
  isLoading,
  isPaired = true,
}: {
  children: React.ReactNode;
  hasError?: boolean;
  idPrefix?: string;
  mountIfError?: boolean;
  isLoading?: boolean;
  isPaired?: boolean;
}) {
  const [showDialog, setShowDialog] = useState(false);
  const [showMusicDialog, setShowMusicDialog] = useState(false);
  const { screenInfo } = useAuth();
  const shouldShowMusicButton = screenInfo?.music_integration_enabled && !isLoading;
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const focusElements = useMemo(() => [
    `${idPrefix}yes_btn`,
    `${idPrefix}no_btn`,
    `${idPrefix}reload_btn`,
    isPaired ? `${idPrefix}more_options_btn` : undefined,
  ].filter(i => !!i) as string[], [idPrefix, isPaired]);
  const moreOptionsFocusElements = useMemo(() => [
    `${idPrefix}resetAppDialog`,
    `${idPrefix}back_btn`
  ], [idPrefix]);

  const [focusedElementIndex, setFocusedElementIndex] = useState(0)

  useEffect(() => {
    setFocusedElementIndex(0);
  }, [showMoreOptions]);

  useEffect(() => {
    const elems = showMoreOptions ? moreOptionsFocusElements : focusElements
    document.getElementById(elems[focusedElementIndex])?.focus()
  }, [focusedElementIndex])

  useEffect(() => {
    if (shouldShowMusicButton && !focusElements.includes(`${idPrefix}music_btn`)) {
      focusElements.splice(focusElements.length - 1, 0, `${idPrefix}music_btn`)
    } else if (!shouldShowMusicButton && focusElements.includes(`${idPrefix}music_btn`)) {
      focusElements.splice(focusElements.length - 1, 1)
    }
  }, [shouldShowMusicButton, focusElements])

  function toggleFocus(by: number) {
    const elems = showMoreOptions ? moreOptionsFocusElements : focusElements
    return setFocusedElementIndex(p => Math.max(0, Math.min(p + by, elems.length - 1)))
  }

  const { isPreviewMode } = useAuth()

  useEffect(() => {
    const rootEl = document.getElementById("rootElement")
    if (rootEl) {
      rootEl.focus();
    }

    function getIfApplies() {
      if (hasError && mountIfError) return true
      if (!hasError && !mountIfError) return true
      return false
    }

    if (!getIfApplies()) return;

    const listener = (e: KeyboardEvent) => {
      if (!showDialog) {
        switch (e.code) {
          case "Escape":
            e.preventDefault();
            setShowDialog(true);
            document.getElementById(focusElements[focusedElementIndex])?.focus();
            break;
          default:
            break;
        }
      } else {
        switch (e.code) {
          case "Escape":
            if (showMoreOptions) {
              setShowMoreOptions(false);
            } else {
              setShowDialog(false);
              document.getElementById("rootElement")?.focus();
            }
            break;
          case "ArrowRight":
            toggleFocus(1)
            break;
          case "ArrowLeft":
            toggleFocus(-1)
            break;
          case "ArrowUp":
            toggleFocus(-2)
            break
          case "ArrowDown":
            toggleFocus(2)
            break
        }
      }
    }

    window.addEventListener("keyup", listener);

    return () => {
      window.removeEventListener("keyup", listener);
    };
  }, [hasError, showDialog, showMoreOptions]);

  useEffect(() => {
    if (showDialog) {
      document.getElementById(`${idPrefix}yes_btn`)?.focus();
    } else {
      const rootEl = document.getElementById("rootElement");
      if (rootEl) {
        rootEl.focus();
      }
    }
  }, [showDialog]);

  return (
    <>
      <div className="absolute inset-0 m-auto">
        {!hasError ? (
          children
        ) : (
          <div
            className="flex h-full min-h-screen w-full flex-col items-center justify-center gap-8"
            style={{
              pointerEvents: showDialog ? "none" : "auto",
            }}
          >
            <p className="text-4xl">Something went wrong!</p>

            <CustomButton
              className="w-44"
              id="retry"
              onClick={() => {
                reloadPage();
              }}
            >
              Retry
            </CustomButton>
            <ResetAppButton id="resetApp" />
          </div>
        )}
      </div>

      {showDialog && !isPreviewMode && (
        <div
          id="popup"
          aria-hidden="true"
          className="absolute flex h-screen w-screen items-center justify-center bg-black bg-opacity-50"
        >
          <div
            aria-hidden="true"
            className="flex flex-col items-center justify-center gap-4 rounded-2xl bg-white px-12 py-12 text-black md:px-[5vw] md:py-[5vh]"
          >
            {showMoreOptions && (
              <div className="mt-4 flex items-center justify-center gap-4">
                <ResetAppButton
                  id={`${idPrefix}resetAppDialog`}
                  aria-hidden={true}
                  tabIndex={-1}
                />
              </div>
            )}
            {
              !showMoreOptions && <>
                <p
                  aria-hidden="true"
                  tabIndex={-1}
                  className="text-2xl font-semibold focus:outline-none md:text-[2vw]"
                >
                  Are you sure you want to exit the app?
                </p>
                <div
                  aria-hidden="true"
                  className="flex items-center justify-center gap-10 pt-6 text-2xl md:text-[1.7vw]"
                >
                  <CustomButton
                    id={`${idPrefix}yes_btn`}
                    aria-hidden="true"
                    tabIndex={-1}
                    onClick={() => {
                      setShowDialog(false);
                      if (isAndroid()) {
                        // handle android exit
                        quitAndroidApp();
                      } else {
                        if (isInsideIframe()) {
                          window.parent.postMessage("exit_app", "*");
                        }
                      }
                    }}
                  >
                    Yes
                  </CustomButton>
                  <CustomButton
                    id={`${idPrefix}no_btn`}
                    aria-hidden="true"
                    tabIndex={-1}
                    onClick={() => {
                      setShowDialog(false);
                    }}
                  >
                    No
                  </CustomButton>
                </div>
                <div
                  aria-hidden="true"
                  className="flex items-center justify-center gap-10 pt-6 text-2xl md:text-[1.7vw]"
                >
                  <CustomButton
                    id={`${idPrefix}reload_btn`}
                    onClick={() => {
                      reloadPage();
                    }}
                  >
                    Reload
                  </CustomButton>
                  {shouldShowMusicButton && (
                    <CustomButton
                      id={`${idPrefix}music_btn`}
                      aria-hidden="true"
                      tabIndex={-1}
                      onClick={() => {
                        setShowMusicDialog(true)
                      }}
                    >
                      Change Music
                    </CustomButton>
                  )}
                  {
                    isPaired &&
                    <CustomButton
                      id={`${idPrefix}more_options_btn`}  // Add this id
                      onClick={() => setShowMoreOptions(true)}
                      className="text-gray-600"
                      aria-hidden="true"
                      tabIndex={-1}
                    >
                      More Options
                    </CustomButton>
                  }
                </div>
              </>
            }
          </div>
        </div>
      )}

      {showMusicDialog && !mountIfError && <MusicSelectionDialog onClose={() => {
        setShowDialog(false)
        setShowMusicDialog(false)
      }} />}
    </>
  );
}

