import reloadPage from "../utils/AndroidBridge";
import CustomButton from "./CustomButton";

interface ResetAppButtonProps {
  id?: string;
  className?: string;
  "aria-hidden"?: boolean;
  tabIndex?: number;
}

export function ResetAppButton(props: ResetAppButtonProps) {
  return (
    <CustomButton
      {...props}
      onClick={() => {
        localStorage.clear();
        reloadPage()
      }}
    >
      Unpair and Restart
    </CustomButton>
  );
}
