import { useEffect, useRef } from "react";
import { useLogs } from "../../hooks/useLogs";

export default function LogsContainer() {
  const { showLogs } = useLogs();
  if (!showLogs) return <></>
  return (
    <LogsPresenter />
  )
}

function LogsPresenter() {
  const bottomRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { logs } = useLogs();

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [logs]);

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    let scrollInterval: any = null;
    let scrollSpeed = 100;
    const maxScrollSpeed = 500;
    const acceleration = 50;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (scrollInterval) return; // Prevent multiple intervals

      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        e.preventDefault();
        const direction = e.key === "ArrowUp" ? -1 : 1;

        const scroll = () => {
          containerRef.current?.scrollBy({
            top: direction * scrollSpeed,
            behavior: "auto"
          });
          scrollSpeed = Math.min(scrollSpeed + acceleration, maxScrollSpeed);
        };

        scroll(); // Initial scroll
        scrollInterval = setInterval(scroll, 100);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        if (scrollInterval) {
          clearInterval(scrollInterval);
          scrollInterval = null;
          scrollSpeed = 100; // Reset speed
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      if (scrollInterval) clearInterval(scrollInterval);
    };
  }, []);

  return (
    <div className="absolute bottom-0 right-0 flex max-h-screen w-96 flex-col-reverse rounded-md bg-black/50 p-2 text-white">
      <button onClick={() => { throw new Error("for testing purposes") }}>Throw error</button>
      <p className="pb-2 font-medium">Logs:</p>

      <div ref={containerRef} className="h-full flex-grow overflow-auto">
        {logs.map((event, idx) => (
          <div
            key={idx.toString()}
            className="w-full break-words border-b border-gray-500 py-2"
          >
            {event}
          </div>
        ))}
        <div ref={bottomRef} className="list-bottom"></div>
      </div>
    </div>
  );
}
