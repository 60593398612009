import { useEffect, useRef, useState } from "react";

interface TizenTestEnvironmentProps {
    onClose: () => void;
}

import SamsungRemote from './SamsungRemote';
import { tizenLoaderScript } from "../utils/tizenLoader";

export default function TizenTestEnvironment(props: TizenTestEnvironmentProps) {
    const [isLoading, setIsLoading] = useState(true);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // @ts-ignore
        window.tizen = {
            application: {
                getCurrentApplication: () => ({
                    exit: () => {
                        props.onClose();
                    }
                })
            }
        };

        // @ts-ignore
        window.webapis = {
            network: {
                getActiveConnectionType: () => 1,
                NetworkState: {
                    GATEWAY_CONNECTED: 'GATEWAY_CONNECTED',
                    GATEWAY_DISCONNECTED: 'GATEWAY_DISCONNECTED'
                },
                addNetworkStateChangeListener: () => { }
            }
        };

        const scriptElement = document.createElement('script');
        scriptElement.text = tizenLoaderScript;
        document.head.appendChild(scriptElement);
        setIsLoading(false);

        return () => {
            // @ts-ignore
            delete window.tizen;
            // @ts-ignore
            delete window.webapis;
        };
    }, [props.onClose]);

    if (isLoading) {
        return null;
    }

    return (
        <>
            <div ref={containerRef}>
                <div id="iframe-container" className="relative h-screen w-screen bg-slate-900 p-0 overflow-hidden">
                    <div id="player-title" className="absolute -z-10" tabIndex={-1}>
                        Signage Studio Player
                    </div>
                    <div id="loading-indicator" className="absolute inset-0 m-auto flex items-center justify-center bg-slate-900">
                        <div className="spinner h-6 w-6 animate-spin rounded-full border-2 border-gray-300 border-t-gray-700" />
                    </div>
                    <embed id="my-iframe" src="/?os=tizen" className="hidden h-full w-full border-none m-auto inset-0 overflow-hidden" />
                    <div id="error-message" className="hidden">
                        <div className="flex flex-col items-center justify-center">
                            <p id="error-message-text" className="text-4xl text-white">Error loading app</p>
                            <button id="retry-button" className="custom_button" autoFocus tabIndex={0}>
                                Retry
                            </button>
                        </div>
                    </div>
                    <div id="customDialog" className="fixed inset-0 z-10 hidden flex-col items-center justify-center bg-black/30">
                        <div id="customDialogContent" className="flex flex-col items-center justify-center gap-6 rounded-3xl bg-slate-900 p-12">
                            <p id="dialogMessage" className="text-4xl text-white">Dialog message</p>
                            <button id="dialogConfirmBtn" className="custom_button">
                                Okay
                            </button>
                        </div>
                    </div>
                    <div id="snackbar" className="fixed bottom-8 left-1/2 -translate-x-1/2 rounded-lg bg-black px-4 py-4 text-3xl text-white opacity-0 transition-opacity">
                        Some text some message..
                    </div>
                </div>
            </div>
            <SamsungRemote />
        </>
    );
}