import { Asset, Screen } from "../../../types";
import generateAssetUrl from "../../utils/generateAssetUrl";
import { useEffect, useState } from "react";
import { LoadingSpinner } from "../LoadingConitainer";

export default function AssetPlayer({
  asset,
  screen,
}: {
  asset?: Asset;
  screen: Screen;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;

  useEffect(() => {
    setIsLoading(true);
    setHasError(false);

    // Preload image
    const img = new Image();
    img.src = generateAssetUrl(asset?.id, "original");

    img.onload = () => {
      setIsLoading(false);
    };

    img.onerror = () => {
      setHasError(true);
      setIsLoading(false);
      if (retryCount < MAX_RETRIES) {
        setTimeout(() => {
          setRetryCount(prev => prev + 1);
        }, 1000 * (retryCount + 1)); // Exponential backoff
      }
    };

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [asset?.id, retryCount]);

  if (hasError) {
    return (
      <div className="flex h-full w-full items-center justify-center bg-gray-100">
        <p className="text-red-500">Failed to load image</p>
      </div>
    );
  }

  return (
    <>
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
          <LoadingSpinner />
        </div>
      )}
      <img
        className={`h-full w-full transition-opacity duration-300 ${isLoading ? 'opacity-0' : 'opacity-100'}`}
        style={{
          // @ts-expect-error - image_fit is a string
          objectFit: screen.image_fit ?? "fill",
        }}
        src={generateAssetUrl(asset?.id, "original")}
        alt={asset?.name || "Asset"}
      />
    </>
  );
}
