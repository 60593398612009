import React, { useEffect, useState } from 'react';
import ReactPlayer, { type ReactPlayerProps } from 'react-player';
import LoadingContainer from '../LoadingConitainer';
import { cn } from '../../lib/utils';
import { useVideoPlayerRef } from '../../hooks/useVideoPlayerRef';
import { useInteraction } from '../../hooks/useInteraction';
import * as Sentry from "@sentry/react";
import { useAudio } from '../../hooks/useAudio';

interface VideoPlayerProps extends ReactPlayerProps {
  url: string;
  className?: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ url, pip = false, playing = true, controls = false, muted = false, loop = false, volume = 1, className, ...props }) => {
  const { hasInteracted } = useInteraction()
  const [isLoading, setIsLoading] = useState(true)
  const [_muted, setMuted] = useState(!hasInteracted)
  const { onVolumeChange: setMusicVolume } = useAudio()
  const { playerRef: ref, onVideoEndedRef } = useVideoPlayerRef();

  function unmute() {
    setMuted(false)
  }

  useEffect(() => {
    if (hasInteracted) unmute()
  }, [hasInteracted])

  useEffect(() => {
    if (muted) setMusicVolume(1)
    else setMusicVolume(0)
  }, [muted])

  return <div
    onClick={unmute}
    className={cn("w-full h-full z-0 bg-black", className)}
  >
    <div
      className='relative h-full w-full pointer-events-none'
    >
      {isLoading && <LoadingContainer />}
      <ReactPlayer
        ref={ref}
        onPause={() => {
          setMusicVolume(1)
          props.onPause?.()
        }}
        onPlay={() => {
          setMusicVolume(0)
          props.onPlay?.()
        }}
        onStart={() => {
          setMusicVolume(0)
          props.onStart?.()
        }}
        stopOnUnmount
        className='react-player'
        width='100%'
        height='100%'
        url={url}
        pip={pip}
        playing={playing}
        controls={controls}
        loop={loop}
        volume={volume}
        muted={_muted}
        {...props}
        id="player-video"
        onError={(...args) => {
          Sentry.captureException(args)
          props.onError?.(...args)
        }}
        onEnded={() => {
          onVideoEndedRef.current?.()
          setMusicVolume(1)
          props.onEnded?.()
        }}
        onReady={(p) => {
          setIsLoading(false)
          setMusicVolume(0)
          props.onReady?.(p)
        }}
      />
    </div>
  </div>
};

export default VideoPlayer;
