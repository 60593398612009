import { Pause, Play, Volume2, VolumeX } from "lucide-react"
import { useEffect, useState } from 'react'
import { useAudio } from "../../hooks/useAudio"
import { MusicAction } from '../../hooks/useScreen/types'
import { cn } from '../../lib/utils'
import { LoadingSpinner } from "../LoadingConitainer"
import { Button } from "../ui/button"

type MusicControllerListeners = {
  onPlay: () => void
  onPause: () => void
  onVolumeChange: (volume: number | undefined) => void
  action?: MusicAction
}

export function MusicController({
  className,
  onVolumeChange,
  onPlay,
  onPause,
  action,
  ...props
}: Omit<React.ComponentPropsWithoutRef<"div">, keyof MusicControllerListeners> &
  MusicControllerListeners) {
  const { volume: _volume, isPlaying, isPending } = useAudio()
  const volume = _volume ?? 1

  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    const observer = new MutationObserver(function () {
      if (document.querySelectorAll(".player-video")?.length) {
        onVolumeChange(0)
      } else {
        onVolumeChange(undefined)
      }
    });
    observer.observe(document.body, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, [])

  useEffect(() => {
    switch (action) {
      case 'pause':
        onPause()
        break
      case 'resume':
        onPlay()
        break
      case 'disable':
        onPause()
        setIsDisabled(true)
        break
      case 'enable':
        setIsDisabled(false)
        break
    }
  }, [action])

  const handlePlayPause = () => {
    if (isPlaying) onPause()
    else onPlay()
  }

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      switch (event.code) {
        case 'Space':
          event.stopPropagation()
          handlePlayPause()
          break
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [isPlaying])

  if (isDisabled) return null

  return (
    <div {...props} className={cn(className, "transform -translate-x-1/2 w-fit bg-background border border-border rounded-lg shadow-lg p-2 overflow-hidden transition-all duration-300 ease-in-out hover:shadow-2xl")}>
      <div className="flex items-center relative overflow-hidden z-10">
        <div className="flex items-center space-x-1">
          <Button disabled={isPending} variant="ghost" size="icon" className="h-8 w-8 transition-transform duration-200 hover:scale-110" onClick={() => {
            handlePlayPause()
          }}>
            {isPending ? <LoadingSpinner /> : isPlaying ? <Pause className="h-4 w-4" /> : <Play className="h-4 w-4" />}
          </Button>
          <Button variant="ghost" size="icon" className="h-8 w-8 transition-transform duration-200 hover:scale-110" onClick={() => {
            onVolumeChange(volume === 0 ? 1 : 0)
          }}>
            {volume > 0 ? <Volume2 className="h-4 w-4" /> : <VolumeX className="h-4 w-4" />}
          </Button>
        </div>
      </div>
    </div>
  )
}
